<template>
  <router-view />
</template>

<script>
import productModule from '../../store/products'

export default {
  beforeCreate () {
    this.$store.registerModule('products', productModule)
  },
  destroyed () {
    this.$store.unregisterModule('products')
  }
}
</script>
