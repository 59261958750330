<template>
    <b-container fluid>
      <b-form @submit.prevent="submit">
        <b-row class="mb-3">
          <b-col cols="12">
            <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Product</h1>
          </b-col>
        </b-row>
        <b-row v-if="isLoading">
          <b-col cols="12">
            <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
          </b-col>
        </b-row>
        <b-row v-if="!isLoading">
          <b-col cols="12">
            <b-row>
              <b-col cols="12" md="12">
                <b-form-group label="Product Name" :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')">
                  <b-form-input :disabled="isSaving" type="text" :state="validationState(errors.errors, 'name')" v-model="product.name" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12">
                <b-form-group label="Product Line" :invalid-feedback="validationInvalidFeedback(errors.errors, 'product_line')">
                  <b-form-input :disabled="isSaving" type="text" :state="validationState(errors.errors, 'product_line')" v-model="product.product_line" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12">
                <b-form-group label="Superior Product Name" :invalid-feedback="validationInvalidFeedback(errors.errors, 'superior_product_name')">
                  <b-form-input :disabled="true" type="text" :state="validationState(errors.errors, 'superior_product_name')" v-model="product.superior_product_name" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12">
                <b-form-group label="Product SKU" :invalid-feedback="validationInvalidFeedback(errors.errors, 'sku')">
                  <b-form-input :disabled="isSaving" type="text" :state="validationState(errors.errors, 'sku')" v-model="product.sku" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                  <b-alert show variant="info">You should enter the unpacked product dimensions. The system will automatically add an allowance for access on the order form.</b-alert>
              </b-col>
              <b-col cols="12" md="4">
                  <b-form-group label="Length (mm)" :state="validationState(errors.errors, 'length')" :invalid-feedback="validationInvalidFeedback(errors.errors, 'length')">
                  <b-input-group prepend="L">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'length')" v-model="product.length" />
                  </b-input-group>
                  </b-form-group>
              </b-col>
              <b-col cols="12" md="4">
                  <b-form-group label="Width (mm)" :state="validationState(errors.errors, 'width')" :invalid-feedback="validationInvalidFeedback(errors.errors, 'width')">
                  <b-input-group prepend="W">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'width')" v-model="product.width" />
                  </b-input-group>
                  </b-form-group>
              </b-col>
              <b-col cols="12" md="4">
                  <b-form-group label="Height (mm)" :state="validationState(errors.errors, 'height')" :invalid-feedback="validationInvalidFeedback(errors.errors, 'height')">
                  <b-input-group prepend="H">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'height')" v-model="product.height" />
                  </b-input-group>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Cost Price" :state="validationState(errors.errors, 'cost_price')" :invalid-feedback="validationInvalidFeedback(errors.errors, 'cost_price')">
                  <b-input-group prepend="£">
                    <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'cost_price')" v-model="product.cost_price" />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Price" :state="validationState(errors.errors, 'price')" :invalid-feedback="validationInvalidFeedback(errors.errors, 'price')">
                  <b-input-group prepend="£">
                    <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'price')" v-model="product.price" />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group  :invalid-feedback="validationInvalidFeedback(errors.errors, 'update_histotric')">
                  <b-form-checkbox
                    id="update-historic"
                    name="update-historic"
                    v-model="updateHistoric"
                    :value="1"
                    :unchecked-value="0">
                    Update Historic Cost Prices
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
                <b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </template>
  
  <script>
  import validation from '../../mixins/validation'
  import { mapActions, mapGetters } from 'vuex'
  
  export default {
    mixins: [validation],
    created () {
      if (this.$route.params.id) {
        this.fetch(this.$route.params.id)
      }
    },
    computed: {
      ...mapGetters('products', ['errors', 'isLoading', 'isSaving', 'single'])
    },
    data () {
      return {
        correspondenceFilter: null,
        upcomingCorrespondenceFilter: null,
        types: [
          { text: 'Simple Product', value: 'simple' },
          { text: 'Grouped Product', value: 'grouped' },
          { text: 'External/Affiliate Product', value: 'external' },
          { text: 'Variable Product', value: 'variable' }
        ],
  
        updateHistoric: false,
  
        product: {
          id: null,
          sku: null,
          name: null,
          length: null,
          width: null,
          height: null,
          product_line: null,
          superior_product_name: null,
          cost_price: null,
          price: null,
        },
      }
    },
    methods: {
      ...mapActions('products', ['fetch', 'save']),
      submit () {
        let data = {
          id: this.$route.params.id,
          sku: this.product.sku,
          name: this.product.name,
          length: this.product.length,
          width: this.product.width,
          height: this.product.height,
          product_line: this.product.product_line,
          superior_product_name: this.product.superior_product_name,
          cost_price: this.product.cost_price,
          price: this.product.price,
          update_historic: this.updateHistoric,
        }
  
        this.save(data).then(response => {
          if([ 200, 201 ].includes(response.status)) this.$router.push({
            name: 'dash.products.list',
            params: {
              notifications: [{
                variant: 'success',
                title: `Product #${response.data.id}`,
                subtitle: `${response.data.name} - £${response.data.price}`,
                text: `The product has been ${this.$route.params.id ? 'updated' : 'created'} successfully.`,
                timeout: 5000
              }]
            }
          })
        })
      }
    },
    watch: {
      single (newUser) {
        this.product = this.single
      }
    }
  }
  </script>
  
  <style>
  
  </style>
  
